/**
 * 套餐礼包API
 */

import request from "@/utils/request";

/**
 * 修改卡券智能换货开关
 * @param params
 * @returns {Promise<any>}
 */
export function comboEditAiExchangeTh(cardId, status) {
  return request({
    url: `seller/card/combo/editAiExchangeTh/${cardId}`,
    method: "post",
    data: {
      status
    }
  });
}

/**
 * 修改礼包智能换货开关
 * @param params
 * @returns {Promise<any>}
 */
export function editAiExchangeTh(comboId, status) {
  return request({
    url: `seller/shopCombo/editAiExchangeTh/${comboId}`,
    method: "post",
    data: {
      status
    }
  });
}

/**
 * 根据card_id查询套餐礼包
 * @param params
 * @returns {Promise<any>}
 */
export function getByCard(params) {
  return request({
    url: "seller/shopCombo/getByCard",
    method: "get",
    params
  });
}

/**
 * 根据card_id查询套餐礼包List
 * @param params
 * @returns {Promise<any>}
 */
export function getListByCard(params) {
  return request({
    url: "seller/shopCombo/getListByCard",
    method: "get",
    params
  });
}

/**
 * 获取绑定卡券列表
 * @param params
 * @returns {Promise<any>}
 */
export function getCanPickedCardList(params) {
  return request({
    url: "seller/card/comboCardListTh",
    method: "get",
    params
  });
}

/**
 * 绑卡记录
 * @param params
 * @returns {Promise<any>}
 */
export function getRecordList(params) {
  return request({
    url: "seller/shopCombo/bindingCardPageTh",
    method: "get",
    params
  });
}

/**
 * 挑选至卡券
 * @param params
 * @returns {Promise<any>}
 */
export function combo2Card(cardId, data) {
  return request({
    url: `seller/shopCombo/bindingToCardTh/${cardId}`,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" }
  });
}

/**
 * 查询店铺套餐礼包列表  GET
 * @param params
 * @returns {Promise<any>}
 */
export function getComboList(params) {
  return request({
    url: "seller/shopCombo/comboListTh",
    method: "get",
    params
  });
}

/**
 * 添加套餐礼包
 * @param params
 * @returns {Promise<any>}
 */
export function addCombo(data) {
  return request({
    url: "seller/shopCombo/addTh",
    method: "post",
    data,
    headers: { "Content-Type": "application/json" }
  });
}

/**
 * 查询店铺套餐礼包,编辑时使用
 * @param params
 * @returns {Promise<any>}
 */
export function getCombo(id) {
  return request({
    url: `seller/shopCombo/comboInfoTh/${id}`,
    method: "get"
  });
}

/**
 * 查询店铺套餐礼包
 * @param params
 * @returns {Promise<any>}
 */
export function getCombos(ids) {
  if (ids) {
    return request({
      url: `seller/shopCombo/combosInfoTh/${ids}`,
      method: "get"
    });
  } else {
    return new Promise(resolve => {
      resolve([]);
    });
  }
}

/**
 * 可换货商品
 * @param params
 * @returns {Promise<any>}
 */
export function replaceGoodsListTh(comboGoodsId, params) {
  return request({
    url: `seller/shopCombo/replaceGoodsListTh/${comboGoodsId}`,
    method: "post",
    data: params
  });
}

/**
 * 可换货商品
 * @param params
 * @returns {Promise<any>}
 */
export function addReplaceGoodsTh(comboId, id, params) {
  return request({
    url: `seller/shopCombo/${comboId}/${id}/addReplaceGoodsTh`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: params
  });
}

/**
 * 删除可替换商品
 * @param params
 * @returns {Promise<any>}
 */
export function deleteReplaceGoodsTh(comboId, id, replaceId) {
  return request({
    url: `seller/shopCombo/${comboId}/${id}/deleteReplaceGoodsTh/${replaceId}`,
    method: "delete"
  });
}

/**
 * 删除店铺套餐礼包
 * @param params
 * @returns {Promise<any>}
 */
export function delCombo(id) {
  return request({
    url: `seller/shopCombo/delTh/${id}`,
    method: "delete"
  });
}

/**
 * 编辑店铺套餐礼包
 * @param params
 * @returns {Promise<any>}
 */
export function editCombo(id, data) {
  return request({
    url: `seller/shopCombo/editTh/${id}`,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" }
  });
}

/**
 * 禁用/启用 店铺套餐礼包
 * @param params
 * @returns {Promise<any>}
 */
export function changeStatus(id, status) {
  return request({
    url: `seller/shopCombo/updataStatusTh/${id}/${status}`,
    method: "post"
  });
}

/**
 * 备注卡券
 * @param params
 * @returns {Promise<any>}
 */
export function editRemark(comboId, data) {
  return request({
    url: `seller/card/combo/editRemarkTh/${comboId}`,
    method: "post",
    data
  });
}

/**
 * 备注礼包
 * @param params
 * @returns {Promise<any>}
 */
export function editRemarkTh(comboId, data) {
  return request({
    url: `seller/shopCombo/editRemarkTh/${comboId}`,
    method: "post",
    data
  });
}

/**
 * 保存店铺强制推介状态
 * @param params
 * @returns {Promise<any>}
 */
export function forceReferrals(data) {
  return request({
    url: `seller/shops/forceReferrals`,
    method: "put",
    data
  });
}

/**
 * 礼包商品上移下移
 * @param params
 * @returns {Promise<any>}
 */
export function moveComboGoodsTh({ combo_id, goods_id, move_type }) {
  return request({
    url: `seller/shopCombo/moveComboGoodsTh/${combo_id}/${goods_id}`,
    method: "post",
    data: { move_type }
  });
}

/**
 * 替换商品上移下移
 * @param params
 * @returns {Promise<any>}
 */
export function moveReplaceGoodsTh({
  combo_id,
  goods_id,
  replace_goods_id,
  move_type
}) {
  return request({
    url: `seller/shopCombo/moveReplaceGoodsTh/${combo_id}/${goods_id}/${replace_goods_id}`,
    method: "post",
    data: { move_type }
  });
}
